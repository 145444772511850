exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-frequently-asked-questions-tsx": () => import("./../../../src/pages/about/frequently-asked-questions.tsx" /* webpackChunkName: "component---src-pages-about-frequently-asked-questions-tsx" */),
  "component---src-pages-about-our-mission-tsx": () => import("./../../../src/pages/about/our-mission.tsx" /* webpackChunkName: "component---src-pages-about-our-mission-tsx" */),
  "component---src-pages-about-our-team-tsx": () => import("./../../../src/pages/about/our-team.tsx" /* webpackChunkName: "component---src-pages-about-our-team-tsx" */),
  "component---src-pages-case-studies-clever-first-aid-tsx": () => import("./../../../src/pages/case-studies/clever-first-aid.tsx" /* webpackChunkName: "component---src-pages-case-studies-clever-first-aid-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-page-tsx": () => import("./../../../src/pages/page.tsx" /* webpackChunkName: "component---src-pages-page-tsx" */),
  "component---src-pages-resources-tsx": () => import("./../../../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */),
  "component---src-pages-technology-xoria-cloud-tsx": () => import("./../../../src/pages/technology/xoria-cloud.tsx" /* webpackChunkName: "component---src-pages-technology-xoria-cloud-tsx" */),
  "component---src-pages-technology-xoria-cube-tsx": () => import("./../../../src/pages/technology/xoria-cube.tsx" /* webpackChunkName: "component---src-pages-technology-xoria-cube-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */)
}

